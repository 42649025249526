<script setup>
import { ref, computed } from "vue";

const $device = useDevice();

const items = ref([
  { id: 1, name: "Solutions for Agencies", link: "/solutions/agencies" },
  { id: 2, name: "Solutions for Startups", link: "/solutions/startups" },
  { id: 3, name: "Solutions for Businesses", link: "/solutions/businesses" },
  { id: 4, name: "Solutions for Enterprises", link: "/solutions/enterprises" },
]);

const backgroundStyles = computed(() =>
  $device.isDesktop
    ? "url(/img/homepage/tailored-solutions-desktop.webp)"
    : $device.isMobile
    ? "url(/img/homepage/tailored-solutions-mobile.webp)"
    : "url(/img/homepage/tailored-solutions-tablet.webp)"
);
</script>

<template>
  <section class="">
    <div class="container px-5 py-20 md:px-7 lg:px-14">
      <div class="grid lg:grid-cols-2">
        <div
          class="tailored-solutions rounded-lg"
          :style="`background-image: ${backgroundStyles}`"
        ></div>
        <div class="lg:ps-2 lg:ms-8 xl:ms-0 h-full flex flex-col" :class="$device.isTablet ? 'ms-20' : 'xl:pe-28'">
          <div class="mt-10 lg:mt-0 w-full md:w-3/5 lg:w-full">
            <Tag tagClass="bg-tagblack text-white">Tailored Solutions</Tag>

            <h2 class="my-4">
              <SplitAnimate text="Team work make the dream work" />
            </h2>
            <span class="description text-black60">
              At Trez Labs, we understand that businesses of different sizes and
              types have unique challenges
              <br class="hidden lg:block xl:hidden" />
              and goals. That's why we offer tailored solutions for agencies,
              startups, and enterprises.
            </span>
          </div>
          <div class="w-full h-full flex items-end mt-16 lg:mt-0">
            <Lists :items="items" class="w-full" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
